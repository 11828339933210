.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-label {
  padding-left: 0 !important;
}


.card-hover {
  opacity: 0.95;
}
.card-hover:hover {
  opacity: 1;
}
.card-hover:hover .card-body {
  background-color: #dddcdc;
}
.card-hover:hover .card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.otp-field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
}
.cursor-pointer {
  cursor: pointer;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
.scroll-container .indicator {
  height: 100%;
  background: #8b0000;
}

.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #8b0000;
  color: #fff;
  z-index: 1;
}
.scroll-to-top .icon {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
  from {
      transform: translateY(2px);
  }
  to {
      transform: translateY(-1px);
  }
}

.custom-youtube-player {
  position: fixed;
  width: 450px;
  height: 300px;
  bottom: 40px;
  right: 40px;
  background: #000;
}
.custom-youtube-player .close,
.custom-youtube-player .handle {
  position: absolute;
  right: 0px;
  top: -30px;
  width: 32px;
  height: 32px;
  background: #000;
  color: #fff;
  opacity: 1;
  padding: 3px;
  cursor: pointer;
}
.custom-youtube-player .handle {
  right: 32px;
  padding-top: 8px;
  cursor: move;
}
.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
}
.fp-container .fp-loader {
  top: 30%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}

.battery-outer {
  height: 210px;
  width: 100px;
  border: 2px solid #222;
  padding: 4px;
  border-radius: 7px;
}
.battery-inner {
  width: 100%;
  margin: 2px 0;
  background-color: #01770a;
}
.battery-empty .battery-inner {
  background: #8b0000;
}
.battery-outer::before {
  content: "";
  width: 24px;
  position: absolute;
  top: -8px;
  height: 8px;
  background: #222;
  left: 49%;
  border-radius: 4px 4px 3px 3px;
}
.battery-outer .battery-charging-indicator {
  position: absolute;
  top: 40%;
  left: 49.2%;
  color: #cbd71d;
  font-size: 40px;
  display: none;
}
.battery-charging .battery-outer .battery-charging-indicator {
  display: block;
  animation: fadeIn 0.8s infinite alternate;
}
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.select2-selection--single{
  height: 32px !important;
}
.select2-container--default .select2-selection--single{
border: 1px solid #cbd0dd;
border-radius: 0.375rem;
}

.export-buttons {
  display: flex;
}

.export-buttons Button {
  margin-right: 0;
}

.login-card{
  padding: 100px 150px ;
}

.btn-login{
  background: linear-gradient(90deg, #1C73C5 0%, #1361A1 100%);
}

.container-login {
  width: 100%;
  overflow-x: hidden;
}

.icon-toggle::after {
  display: none !important;
}

/*//////////////////////////*/
/* Style for slim scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}

.dropdown-header {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-bottom: 0;
  font-size: 12px;
  color: #3e465b;
  font-weight: 800;
}

.avtarToggle::after {
  display: none !important;
}

.navbar-top .dropdown-menu {
  margin-top: 0rem;
}

/*//////////////////////////*/

/* styles.css */

/* Custom styles for the tabs container */
.custom-tabs-container {
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #dee2e6; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding around the tabs */
}


.vertical-divider {
  border-left: 1px solid #ccc; /* Adjust the color and thickness as needed */
  height: 100%; /* Adjust the height of the divider */
  margin: 0 10px; /* Adjust the margin to create space around the divider */
}


.viewButton::after
{
  margin-left: 10px;
}
/* .ag-theme-quartz  {
  height: 1px !important ;
  min-height: 200px !important;
  max-height: 100%;
} */
.ag-advanced-filter-header
{
  display: none !important;
}
.customclass
{
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #3874ff;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #004dff;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #2d5dcc;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #3874ff;
  --phoenix-btn-disabled-border-color: transparent;
}
.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {z-index: 999;}
.ag-row.ag-row-level-0.ag-row-position-absolute .ag-cell-focus {overflow: visible !important;}

.profiledetail
{
  font-size: 14px;
  font-weight: 500;
}
.profiledetail span
{
  font-size: 12px;
}
.numberDiv
{
  display: inline-block !important;
}

.listViwP
{
  font-size: 13px !important;
}

.MuiStepper-root .MuiStepper-horizontal .MuiStepper-alternativeLabel .css-10mg1vw-MuiStepper-root {
  z-index: 0;
}

.scrollable-container {
  max-height: 92vh;
  overflow-y: auto;
}

.my-fieldset {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding-bottom: 14px;
}

.my-legend {
  width: auto;
  position: absolute;
  top: -10px;
  left: 10px;
  background: #6e7891;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;

}


.my-span {
    margin-left: 10px;
}

.lbl{
  font-size: 14px;
}

.easy {
    width: auto;
    padding: 5px 10px;
    background: #d5ffd6;
    color: #1c441c;
    font-weight: 500;
    border-radius: 2px;
    margin-right: 10px;
}

.nav-item-divider {
  position: relative;
}

.nav-item-divider::after {
  content: "|";
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
}

.subject-counts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  justify-items: center;
  align-items: center;
}

.rounded-circlee {
  width: 100px;
  padding: 5px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* Change this as per your design */
  text-align: center;
}

.transition-text {
  max-height: 60px; /* Adjust as needed */
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.transition-text.expanded {
  max-height: 500px; /* Adjust as needed */
}


